@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  font-family: 'Poppins', sans-serif;
  background-color: #E5E5E5;
}

.fit {
  //min-height: calc(100vh - 30px);
  min-height: 100vh;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.circleStats {
  background: linear-gradient(180deg, #A6B7D4 50%, transparent 50%), 
      linear-gradient(230deg, #4C6FFF 50%, transparent 50%),
      linear-gradient(360deg, #FF92AE 50%, transparent 50%);
        
}

.gradient-text {
  background: -webkit-linear-gradient(left, #60c657 30%, #35aee2 60%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.anim-button {
  background-color: rgb(32, 129, 226);
  background: -webkit-linear-gradient(left, #4C6FFF, #bb65ff);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  margin-right: 15px;
}

/* KeyFrames */
@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.hr-lines{
  position: relative;
  max-width: 600px;
  text-align: center;
}

.hr-lines:before{
  content:" ";
  height: 1px;
  width: 130px;
  background: #A0A0A0;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
}


.hr-lines:after{
  content:" ";
  height: 1px;
  width: 130px;
  background: #A0A0A0;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
}

@media screen and (max-width: 670px) {
  .hr-lines:before,
  .hr-lines:after {
   width: 80px
  }
}

@media screen and (max-width: 560px) {
  .hr-lines:before,
  .hr-lines:after {
   display: none;
  }
}