.container {
    text-align: center;
  }

  .toggleSwitch {
    position: relative;
    width: 42px;
    display: inline-block;
    text-align: left;
    top: 4px;
  }

  .checkbox {
    display: none;
  }

  .label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
  }

  .inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }

  .inner:before,
  .inner:after {
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    line-height: 20px;
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
    font-size: 11px;
  }

  .inner:before {
    content: "ON";
    padding-left: 5px;
    background-color: #576292;
    color: #fff;
  }

  .inner:after {
    content: "OFF";
    padding-right: 5px;
    background-color: #BECAFF;
    color: #fff;
    text-align: right;
  }

  .switch {
    display: block;
    width: 15px;
    height: 15px;
    margin: 2px;
    background: #1B44FE;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 24px;
    border: 0 solid #bbb;
    border-radius: 50%;
    transition: all 0.3s ease-in 0s;
  }

  .checkbox:checked + .label .inner {
    margin-left: 0;
  }

  .checkbox:checked + .label .switch {
    right: 0px;
  }